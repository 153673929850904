<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="table"
        title="설비 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="key"
        @rowClick="rowClick"
        @linkClick="rowClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="기기정비 이력기록서" icon="assignment" @btnClicked="btnRecord" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div v-if="isCheck === '1'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <c-table
        ref="table"
        title="설비별 점검항목"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="record.equipmentResultList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        noDataLabel="설비에 대한 점검항목이 없습니다."
        rowKey="checkItemSeq"
        cardClass="topcolor-orange"
      >
        <template slot="table-button">
        </template>
      </c-table>
    </div>
    <div v-if="isCheck === '2'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailForm" title="설비 정비결과" topClass="topcolor-orange" bgClass="">
        <template slot="card-detail">
          <div v-if="editable && record.maintenanceCompleteFlagName" class="col-3">
            <c-label-text title="정비후 점검결과" :value="record.maintenanceCompleteFlagName"></c-label-text>
          </div>
          <div v-if="editable && record.inspResultAfterMemName" class="col-3">
            <c-label-text title="정비후 점검결과" :value="record.inspResultAfterMemName"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceTimeT" class="col-3">
            <c-label-text title="정비시간" :value="record.maintenanceTimeT"></c-label-text>
          </div>
          <div v-if="editable && record.stopDrivingTimeT" class="col-3">
            <c-label-text title="운전정지시간" :value="record.stopDrivingTimeT"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceQualifications" class="col-12">
            <c-label-text title="정비자 자격사항" :value="record.maintenanceQualifications"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceCause" class="col-12">
            <c-label-text title="정비원인" :value="record.maintenanceCause"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceContent" class="col-12">
            <c-label-text title="정비내용" :value="record.maintenanceContent"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceResultSummary" class="col-12">
            <c-label-text title="정비결과요약" :value="record.maintenanceResultSummary"></c-label-text>
          </div>
          <div v-if="editable && record.reviewOpinionOfMananger" class="col-12">
            <c-label-text title="관리자의 검토의견" :value="record.reviewOpinionOfMananger"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-record-detail',
  props: {
    selectedRow2: {
      type: Object,
      default: () => ({
        equipmentMaintainList: [],
        month: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      record: {
        // 점검쪽
        minEquipmentCheckId: '',
        plantCd: '',
        plantName: '',
        checkDeptCd: '',
        checkDeptName: '',  
        checkUserId: '',
        checkUserName: '',
        sopMocId: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'N',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',
        equipmentResultList: [],
        // 정비쪽
        minEquipmentMaintenanceId: '',
        maintenanceStatusCd: null,
        equipmentCheckTypeCd: null,
        memDeptEntrantUserId: '',
        maintenanceDueDate: '',
        maintenanceDueUserId: '',
        maintenanceUserId: '',
        maintenanceQualifications: '',
        maintenanceDate: '',
        maintenanceResultSummary: '',
        inspResultAfterMemCd: null,
        maintenanceCompleteFlag: null,
        maintenanceCause: '',
        maintenanceContent: '',
        maintenanceTime: '',
        stopDrivingTime: '',
        reviewOpinionOfMananger: '',
      },
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            label: "설비명(관리번호)",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "statusTypeCd",
            field: "statusTypeCd",
            label: "현황분류",
            style: 'width:80px',
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'Y', stepperMstNm: '점검', colorClass: 'green' },
              { stepperMstCd: 'N', stepperMstNm: '정비', colorClass: 'orange' },
            ],
            align: "center",
            sortable: true,
          },
          {
            name: "deptName",
            field: "deptName",
            label: "점검부서/정비입회자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "typeName",
            field: "typeName",
            label: "점검/정비유형",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          
          {
            label: '점검/정비',
            align: 'center',
            sortable: false,
            child: [
              {
                name: "realDateT",
                field: "realDateT",
                label: "실제일",
                style: 'width:110px',
                align: "center",
                sortable: true,
                type: "link",
              },
              {
                name: "userName",
                field: "userName",
                label: "실제자",
                style: 'width:100px',
                align: "center",
                sortable: true,
              },
              {
                name: "resultName",
                field: "resultName",
                label: "결과",
                style: 'width:90px',
                align: "center",
                sortable: true,
              },
            ]
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
        ],
        data: [],
        height: '300px'
      },
      grid2: {
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            style: 'width:200px',
            // type: 'textarea',
            align: 'left',
            sortable: true
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            label: '점검결과',
            align: 'left',
            style: 'width:250px',
            sortable: true
          },
          {
            name: 'checkWayName',
            field: 'checkWayName',
            label: '검사방법',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'judgmentResultTypeName',
            field: 'judgmentResultTypeName',
            label: '적합/부적합',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'decisionBasis',
            field: 'decisionBasis',
            label: '판정기준',
            // type: 'textarea',
            style: 'width:200px',
            align: 'left',
            sortable: true
          },
          {
            name: 'checkCaution',
            field: 'checkCaution',
            label: '점검시 안전사항',
            style: 'width:200px',
            align: 'left',
            sortable: true
          },
        ],
        data: [],
        height: '480px'
      },
      isCheck: '0',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      getUrl: '',
      chkResultItems: [],
      chkRsltYnItems: [],
      safFacilityItems: [],
      title: '',
    };
  },
  watch: {
    'selectedRow2.month'() {
      this.isCheck = '0';
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting

      this.getList();
    },
    getList() {
      this.grid.data = this.$_.clone(this.selectedRow2.equipmentMaintainList);
        
      this.rowClick(this.grid.data[0])
    },
    rowClick(row) {
      if (row.statusTypeName === '점검') {
        this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
        this.isCheck = '1';
      } else { 
        this.getUrl = selectConfig.sop.min.maintenance.plan.get.url;
        this.isCheck = '2';
      }
      this.$http.url = this.$format(this.getUrl, row.equipKey);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.record = _result.data;
      });
    },
    btnRecord() {
      this.popupOptions.target = () => import(`${"@/pages/mdm/fim/equipmentPreview.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '40%';
      this.popupOptions.param = this.record;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
